import React from "react"
import Layout from "../components/layout"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { ReactComponent as CheckIcon } from "../styles/icons/check-solid.svg"
import MERV16 from "./resources/MERV-16_ASABE-Response.pdf"
import MERV16Text from "./resources/MERV-16.pdf"
import ASABEX613 from "./resources/ASABE-X613-Cabin-Filtration.pdf"
import CPFT from "./resources/CAF 4 Cab Protection Factors Template ISO R20245.pdf"
import FGT from "./resources/Failture-of-Gravimetric-Testing.pdf"
import FFPF from "./resources/FIT FACTOR-PROTECTION FACTOR.pdf"

import rdStyle from "../styles/rd.module.scss"
import { Helmet } from "react-helmet"
import data from "../data/rd"

export default function RD() {
  const { handshake } = useStaticQuery(
    graphql`
      query rdImage {
        handshake: file(relativePath: { eq: "handshake.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
            fixed(width: 375) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    `
  )
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.title} | Clean Air Filter</title>
      </Helmet>
      <section className="w-60">
        <h1>
          <span className="tail">
            <span className="head">{data.content.section1.h1.head}</span>{" "}
            {data.content.section1.h1.tail}
          </span>
        </h1>
        <div className={rdStyle.floatRight}>
          <Img
            style={{ borderRadius: 5 }}
            fluid={handshake.childImageSharp.fluid}
            alt="will put"
          ></Img>

          <p style={{ margin: "1rem 0" }}>Industries of focus:</p>
          <div className={rdStyle.grid}>
            <p className={rdStyle.cell}>
              <CheckIcon></CheckIcon>&#160;Foundries
            </p>
            <p className={rdStyle.cell}>
              <CheckIcon></CheckIcon>&#160;Agriculture
            </p>
            <p className={rdStyle.cell}>
              <CheckIcon></CheckIcon>&#160;Mining
            </p>
            <p className={rdStyle.cell}>
              <CheckIcon></CheckIcon>&#160;Landfills
            </p>
          </div>
        </div>
        <article className="w-60">
          <p>{data.content.section1.p1}</p>
          <p>{data.content.section1.p2}</p>
          <p>{data.content.section1.p3}</p>
          <p>{data.content.section1.p4}</p>
          <ol>
            {data.content.section1.ol.map((item, index) => (
              <li key={index}>
                <p>{item}</p>
              </li>
            ))}
          </ol>
        </article>
      </section>

      <article className="w-60">
        <section>
          <h2>{data.content.sectionFilterFitTest.h2}</h2>
          <p>
            <a
              className="download-link"
              href={FFPF}
              target={"_blank"}
              rel="noreferrer"
            >
              {data.content.sectionFilterFitTest.a0}
            </a>
          </p>
          <p>
            <a
              className="download-link"
              href={CPFT}
              target={"_blank"}
              rel="noreferrer"
            >
              {data.content.sectionFilterFitTest.a1}
            </a>
          </p>
          <p>
            <a
              className="download-link"
              href={ASABEX613}
              target={"_blank"}
              rel="noreferrer"
            >
              {data.content.sectionFilterFitTest.a2}
            </a>
          </p>
        </section>
        <section>
          <h2>{data.content.section2.h2}</h2>
          <p>
            <a
              className="download-link"
              href={FGT}
              target={"_blank"}
              rel="noreferrer"
            >
              {data.content.section2.a}
            </a>
          </p>
        </section>
        <section style={{ display: "none" }}>
          <h2>{data.content.section3.h2}</h2>

          <p>
            <a
              className="download-link"
              href={MERV16Text}
              target={"_blank"}
              rel="noreferrer"
            >
              MERV 16 is Not Applicable to Cabin Filtration
            </a>
          </p>
          <p>
            <a
              className="download-link"
              href={MERV16}
              target={"_blank"}
              rel="noreferrer"
            >
              {data.content.section3.a}
            </a>
          </p>
        </section>
      </article>
    </Layout>
  )
}
